import redmark1 from '../assets/red-1.png'
import redmark2 from '../assets/red-2.png'
import redmark3 from '../assets/red-3.png'
import redmark4 from '../assets/red-4.png'
import redmark5 from '../assets/red-5.png'
import redmark6 from '../assets/red-6.png'
import redmark7 from '../assets/red-7.png'
import redmark8 from '../assets/red-8.png'
import redmark9 from '../assets/red-9.png'
import redmark10 from '../assets/red-10.png'
import redmark11 from '../assets/red-11.png'
import redmark12 from '../assets/red-12.png'
import redmark13 from '../assets/red-13.png'
import redmark14 from '../assets/red-14.png'
import redmark15 from '../assets/red-15.png'


const MarkerRed = [
  {
    image: redmark1,
  },
  {
    image: redmark2,
  },
  {
    image: redmark3,
  },
  {
    image: redmark4,
  },
  {
    image: redmark5,
  },
  {
    image: redmark6,
  },
  {
    image: redmark7,
  },
  {
    image: redmark8,
  },
  {
    image: redmark9,
  },
  {
    image: redmark10,
  },
  {
    image: redmark11,
  },
  {
    image: redmark12,
  },
  {
    image: redmark13,
  },
  {
    image: redmark14,
  },
  {
    image: redmark15,
  },
]

export default MarkerRed;